

















import { PropType } from 'vue';
import { defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import { extractImage } from '@vsf-enterprise/amplience';
import { RenderPoiSource, PoiInterface } from '~/modules/amplience/types';
import { useDAMImage } from '~/modules/amplience/composables/useDAMImage';

export default defineComponent({
  name: 'RenderPOI',
  props: {
    poi: {
      type: Object as PropType<PoiInterface>,
      default: () => ({}),
    },
    sources: {
      type: Array as PropType<RenderPoiSource[]>,
      default: () => [],
    },
    layer: {
      type: String,
      default: ''
    },
    altText: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const ctx = useContext();
    const { metadata, fetch } = useDAMImage(ctx);
    const imageAlt = ref<string>('');

    const setSrcset = (source: RenderPoiSource, poi: PoiInterface): string => {
      const { url } = extractImage(poi.image);
      const { query } = poi;
      let srcset = `${url}?${query || (`&sm=aspect&h=${source.height}&aspect=${source.aspect}`)}`;

      if (query && !query.includes('crop')) {
        srcset += `&sm=aspect&h=${source.height}&aspect=${source.aspect}`;
      }

      if (props.layer) {
        srcset += `&$${props.layer}$`;
      }

      return srcset.replace(/ /g, '%20');
    };

    const getImageSrc = (poi: PoiInterface) => {
      const image = extractImage(poi.image);

      if (poi.query || props.layer) {
        image.url += '?';

        if (poi.query) {
          image.url += `&${poi.query}`
        }

        if (props.layer) {
          image.url += `&$${props.layer}$`;
        }
      }

      return image.url
    };

    onMounted(async () => {
      await fetch(props.poi.image.name);
      imageAlt.value = metadata?.value?.image_alt_data?.alt_text || props.altText || props.poi.image.name;
    })

    return {
      setSrcset,
      getImageSrc,
      imageAlt,
    };
  },
});
